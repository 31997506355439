<template>
  <div>
    <div class="row pt-3 px-3 py-3">
      <div v-if="data.length" v-loading="loading" class="col-lg-12 mb-1">
        <b-table
          id="data-table"
          ref="table"
          primary-key="$id"
          sticky-header="500px"
          no-border-collapse
          :current-page="currentPage"
          :per-page="perPage"
          responsive
          :bordered="bordered"
          :busy="loading"
          hover
          selectable
          :items="rows"
          :fields="columns"
          :sort-by.sync="_sortBy"
          :sort-desc.sync="_sortDesc"
          :tbody-transition-props="_transition"
          @row-selected="onRowSelected"
        >
          <template #head()="_data">
            <b-form-checkbox
              v-if="_data.label.toLowerCase() === 'select'"
              :checked="allSelected"
              @change="(event) => handleSelectAll(event)"
            >
            </b-form-checkbox>
            <span v-else class="text-primary">{{ _data.label }}</span>
          </template>

          <template #cell(select)="{ rowSelected, index }">
            <b-form-checkbox
              :checked="rowSelected"
              @change="(event) => handleRowSelectionChange(index, event)"
            >
            </b-form-checkbox>
          </template>

          <template #empty="scope">
            <h4>{{ scope.emptyText }}</h4>
          </template>

          <template #emptyfiltered="scope">
            <h4>{{ scope.emptyFilteredText }}</h4>
          </template>

          <template #cell(actions)="row">
            <slot name="action" :row="row"> </slot>
          </template>

          <template #cell()="row">
            <slot name="cell" :row="row"> {{ row.value }} </slot>
          </template>
        </b-table>
      </div>
      <div v-else class="col-lg-12 pt-3 px-3 py-3">
        <slot name="empty" :text="emptyText">
          <p class="text-muted center">
            {{ emptyText }}
          </p>
        </slot>
      </div>
    </div>
    <div class="row pt-3 px-3 py-3">
      <el-pagination
        class="col-lg-12 mb-1"
        :current-page.sync="currentPage"
        :page-sizes="[20, 50, 70, 100]"
        :page-size="perPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
        :disabled="data.length == 0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
/**
 * @typedef {Object} CellOverEvent
 * @property {Number} id
 * @property {String} property
 * @property {HTMLTableCellElement} target
 */
export default {
  name: "TableComponent",
  props: {
    showAction: {
      type: Boolean,
      default: false,
    },
    /**
     *
     */
    emptyText: {
      type: String,
      default: "No record selected",
    },
    /**
     *
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     *
     */
    enableSelect: {
      type: Boolean,
      default: true,
    },
    /**
     *
     */
    data: {
      type: Array,
      default: () => [],
    },
    /**
     *
     */
    fields: {
      type: Array,
      default: () => [],
    },
    /**
     *
     */
    bordered: {
      type: Boolean,
      default: false,
    },
    /**
     *
     */
    sortBy: {
      type: String,
      default: "",
    },
    /**
     *
     */
    sortDesc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      totalRows: 1,
      perPage: 25,
      currentPage: 1,
      allSelected: false,
    };
  },
  computed: {
    _transition() {
      return {
        name: "cell",
      };
    },
    _sortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("sort:by", value);
      },
    },
    _sortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("sort:direction", value);
      },
    },
    /**
     *
     */
    rows() {
      return [...this.data];
    },
    /**
     *
     */
    total() {
      return this.rows.length;
    },
    /**
     *
     */
    columns() {
      let columns = [];
      if (this.enableSelect) columns.push("select");
      if (this.showAction) columns.push({ key: "actions", label: "Actions" });
      columns = [...columns, ...this.fields];
      return columns;
    },
  },
  mounted() {
    const table = this.$refs["table"];
    if (table) {
      table.$el.addEventListener("mouseover", this.mouseOver, false);
      table.$el.addEventListener("mouseout", this.mouseOver, false);
    }
  },
  beforeDestroy() {
    const table = this.$refs["table"];
    if (table) {
      table.$el.removeEventListener("mouseover", this.mouseOver);
      table.$el.removeEventListener("mouseout", this.mouseOver);
    }
  },
  methods: {
    /**
     * @param {MouseEvent} event
     */
    mouseOver(event) {
      const target = event.target;
      if (target.localName === "td") {
        let rowID, cellIndex;
        try {
          rowID = target.parentNode.getAttribute("id").split("_").pop();
          cellIndex = target.cellIndex;
        } catch (error) {
          return false;
        }
        /**
         *@type {CellOverEvent}
         */
        const payload = {
          id: parseInt(rowID),
          property: this.columns[cellIndex].key || this.columns[cellIndex],
          target,
        };
        this.$emit("table:cellover", payload);
      }
    },
    /**
     * @param {MouseEvent} event
     */
    // eslint-disable-next-line no-unused-vars
    mouseOut(event) {
      this.$emit("table:cellout");
    },
    /**
     *
     */
    handleSizeChange(val) {
      this.perPage = val;
      this.$nextTick(() => {
        this.$emit("sizeChange", this.perPage);
      });
    },
    /**
     *
     */
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    /**
     *
     */
    onRowSelected(items) {
      this.selected = items;
      this.$nextTick(() => {
        this.$emit("selected", JSON.parse(JSON.stringify(this.selected)));
      });
    },
    /**
     *
     */
    selectAllRows() {
      this.$refs.table.selectAllRows();
    },
    /**
     *
     */
    clearSelected() {
      this.$refs.table.clearSelected();
    },
    /**
     *
     */
    handleSelectAll(state) {
      if (state) this.selectAllRows();
      else this.clearSelected();
    },
    /**
     *
     */
    handleRowSelectionChange(index, state) {
      if (state) this.$refs.table.selectRow(index);
      else this.$refs.table.unselectRow(index);
    },
  },
};
</script>
<style lang="css">
table#data-table .cell-move {
  transition: transform 1s;
}
table#data-table .cell-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
